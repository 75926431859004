import { createStore as _createStore } from 'vuex'
import { accountsService } from "fairstatcore/services/index.js"
import { organizationService, reportsService, productsService } from '../services/index';
import { Account as AccountModule, Notification as NotificationModule } from "fairstatcore/store/index.js";
import { Reports as ReportsModule, Organization as OrganizationModule, Product as ProductModule } from "./modules/index.js";
import { createRouter } from '@/router/index.js';
const router = createRouter();

export function createStore () {
  return _createStore({
    namespaced: true,
    state: {
      tabulator: null,
      tabulatorDataLoading: false,
      instructionIsRunning: localStorage.getItem('tutorial') === null ? true : localStorage.getItem('tutorial') !== 'false',
      currentMarketplace: localStorage.getItem('currentMarketplace') || 'WB',
    },
    modules: {
      accounts: AccountModule(accountsService),
      organization: OrganizationModule(organizationService),
      notifications: NotificationModule(accountsService, router),
      reports: ReportsModule(reportsService),
      product: ProductModule(productsService),
    },
    mutations: {
      setItem(state, { item, value }) {
        state[item] = value;
      },
    },
    actions: {},
    getters: {},
  })
}
