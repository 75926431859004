// import InitService from 'fairstatcore/services/api/Init';
import CommonService from 'fairstatcore/services/Common';
import SellersService from './api/sellers';
import BrandsService from './api/brands';
import ApiKeysService from './api/apikeys';
import CategoriesService from "./api/categories";
import ProductsService from "./api/Products";
import OrganizationService from "./api/Organization";
import ReportsService from "./api/Reports";

export const lkService = new CommonService({
  backendURL: import.meta.env.VITE_APP_APFS_URL,
  prefix: '',
});

export const dashboardService = new CommonService({
  backendURL: import.meta.env.VITE_APP_APFSLK_URL,
  prefix: 'dashboard',
});

export const sellersService = new SellersService({
  backendURL: import.meta.env.VITE_APP_APFS_URL,
  prefix: 'sellers',
});

export const brandsService = new BrandsService({
  backendURL: import.meta.env.VITE_APP_APFS_URL,
  prefix: 'brands',
});

export const apiKeysService = new ApiKeysService({
  backendURL: import.meta.env.VITE_APP_APFSIS_URL,
  prefix: '',
});

export const categoriesService = new CategoriesService({
  backendURL: import.meta.env.VITE_APP_APFS_URL,
  prefix: 'categories',
});

export const productsService = new ProductsService({
  backendURL: import.meta.env.VITE_APP_APFS_URL,
  prefix: 'products',
});

export const organizationService = new OrganizationService({
  backendURL: import.meta.env.VITE_APP_APFSLK_URL,
  prefix: '',
});

export const importService = new CommonService({
  backendURL: import.meta.env.VITE_APP_APFSIS_URL,
  prefix: 'import',
});

export const reportsService = new ReportsService({
  backendURL: import.meta.env.VITE_APP_APFSIS_URL,
  prefix: '',
});
